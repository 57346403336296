import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import context from "../context";
import { Error } from "../components/Typography";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../network/mutations";

// Components
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import SphereLogo from "../components/SphereLogo";

const Auth = () => {
  const { setJWT, firebaseLoading } = useContext(context);
  const [loginError, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { error, loading }] = useMutation(LOGIN_MUTATION, {
    variables: {email, password}, 
    onError: setError
  });

  const isLoading = [firebaseLoading, loading].some(Boolean);

  useEffect(() => {
    setPassword("");
  }, [error]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (email && password) {
        login({ variables: { email, password } })
        .then(({ data: { login: { admin, jwt } } }) => {
          if (admin) {
            setJWT(jwt);
          } else {
            setError("You must be an admin.")
          }
        })
        .catch(() => {
          //
        })
      }
    },
    [email, password, login]
  );

  return (
    <Container>
      <LogoContainer isLoading={isLoading}>
        <SphereLogo />
      </LogoContainer>
      
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <TextInput
          attrs={{ type: "email", placeholder: "Email" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextInput
          attrs={{ type: "password", placeholder: "Password" }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {loginError &&
          loginError.graphQLErrors.map(({ message }, i) => (
            <Error key={i} style={{ textAlign: "unset" }}>
              {message}
            </Error>
          )
        )}

        <Button loading={isLoading} cta={true} type="submit">
          Login
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  background: black;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  position: relative;
  top: ${({ isLoading }) => (isLoading ? 80 : 0)}px;
  transition: top 0.8s cubic-bezier(0.24, 1, 0.32, 1);
`;

const Form = styled.form`
  width: 300px;
  margin: 20px;
  opacity: ${({ isLoading }) => Number(!isLoading)};
  transition: opacity 0.6s cubic-bezier(0.24, 1, 0.32, 1);
`;

export default Auth;
