import React from 'react';
import styled from 'styled-components';
import logo from '../images/sphere-logo.png';

const SphereLogo = () => <Sphere src={logo} alt="Sphere Logo" />

const Sphere = styled.img`
  height: 40px;
`;

export default SphereLogo;