import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import get from "lodash/get";
import PropTypes from "prop-types";
import context from "../context";

import Button from "./Button";
import MenuDetail from "./MenuDetail";
import MenuHeader from "./MenuHeader";
import variables from "../variables";
import { H4 } from "../components/Typography";

const _propTypes = {
  setShowModal: PropTypes.func.isRequired,
};

const Account = ({ setShowModal }) => {
  const { currentUser, setToken, logoutWithFirebase } = useContext(context);
  const userLoading = get(currentUser, "loading", false);
  const name = get(currentUser, "data.name", "");
  const email = get(currentUser, "data.email", "");
  const supportEmailHref = `mailto:support@sphere.guide`;

  const handleLogout = useCallback(() => {
    setToken(null);
    logoutWithFirebase();
  }, [setToken, logoutWithFirebase]);

  const handleCloseModal = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <Container>
      <MenuHeader onClose={handleCloseModal}>Your Account</MenuHeader>

      <Name>{name}</Name>
      <Email>{email}</Email>

      <MenuDetail
        loading={userLoading}
        title="Example"
        value="1"
      />

      <MenuControls>
        <Button
          style={{ marginBottom: 10 }}
          onClick={() => window.location.href = supportEmailHref}
          cta={false}
        >
          Contact Support
        </Button>
        <Button onClick={handleLogout} cta={false}>
          Logout
        </Button>
      </MenuControls>
    </Container>
  );
};

Account.propTypes = _propTypes;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .buttons {
    height: 90px;
  }
`;

const MenuControls = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const Name = styled(H4)`
  color: ${variables.white};
  margin: 0;
`;
const Email = styled(H4)`
  color: ${variables.grey_primary};
`;

export default Account;
