import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import moment from "moment";
import PropTypes from "prop-types";
import context from "../context";
import Navigation from "../components/Navigation";
import Modal from "../components/Modal";
import variables from "../variables";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const Lifecycle = ({ data, user }) => {
  const { setToken, setLifecycle } = useContext(context);
  const [isRendered, setIsRendered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lifecycleData, setLifecycleData] = useState([]);

  // On Render Animation
  useEffect(() => {
    setTimeout(() => setIsRendered(true), 200);
  }, []);

  useEffect(() => {
    const lifecycleData = data.events.map((evt) => {
      const evtObject = JSON.parse(JSON.stringify(evt));
      const d = moment(evt.timestamp);

      if (evtObject.meta && evtObject.event === "booked session") {
        // console.log("evtObject with meta", evtObject);
        // const sessionObj = JSON.parse(JSON.stringify(evtObject.meta));
        // Will not display anything if there is no session id
        const sessionObj = JSON.parse(evtObject.meta);
        evtObject.sessionId = sessionObj.sessionId;
      }

      evtObject.date = d.format("YYYY-MM-DD");
      evtObject.time = d.format("hh:mm:ss A");

      return evtObject;
    });

    // console.log('lifecycleData', lifecycleData);
    const sortedData = lifecycleData.sort(function (a, b) {
      return new Date(b.timestamp) - new Date(a.timestamp);
    });

    setLifecycleData(sortedData);
  }, [data]);

  const _closeLifecycle = () => {
    setLifecycle({});
    setLifecycleData([]);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date / Time",
        columns: [
          {
            Header: "Date",
            accessor: "date",
          },
          {
            Header: "Time",
            accessor: "time",
          },
        ],
      },
      {
        Header: "Info",
        columns: [
          {
            Header: "Event",
            accessor: "event",
          },
          {
            Header: "Session ID",
            accessor: "sessionId",
            Cell: ({ value }) => {
              return (
                <div style={{ backgroundColor: "transparent" }}>
                  <a
                    href={`https://grafana.sphereishere.com/d/QCOqzRKZz/session-breakdown-by-id?orgId=1&var-var_sid=${value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: variables.white }}
                  >
                    {value}
                  </a>
                </div>
              );
            },
          },
          {
            Header: "Admin",
            accessor: "meta.admin",
          },
        ],
      },
    ],
    []
  );

  return (
    <Container isRendered={isRendered}>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <Navigation
        setToken={setToken}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <Styles>
        <Header>
          {user.name} ({user.email})
          <Close onClick={_closeLifecycle}>&times;</Close>
        </Header>
        <Table columns={columns} data={lifecycleData} />
      </Styles>
    </Container>
  );
};

Lifecycle.propTypes = {};

export default Lifecycle;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  opacity: ${({ isRendered }) => (isRendered ? 1 : 0)};
  transition: opacity 2.4s cubic-bezier(0.24, 1, 0.32, 1);
`;

const Header = styled.div`
  color: white;
  font-size: 1.9em;
  margin-bottom: 20px;
`;

const Close = styled.span`
  float: right;
  cursor: pointer;
  font-size: 1em;
`;

const Styles = styled.div`
  padding: 1rem;

  table {
    width: 85%;
    color: white;
    border-spacing: 0;
    border: 1px solid white;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid white;
      border-right: 1px solid white;

      :last-child {
        border-right: 0;
      }
    }
  }
`;
