import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      admin
      email
      type
    }
  }
`;

// Can accept either id or email, no need for both parameters
export const GET_USER_LIFECYCLE = gql`
  query getUser($id: ID, $email: String) {
    getUser(id: $id, email: $email) {
      id
      name
      email
      type
      lifecycle {
        updatedAt
        events {
          event
          timestamp
          meta
        }
      }
    }
  }
`;
