import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError }        from '@apollo/client/link/error';
import { setContext }     from '@apollo/client/link/context';
import fetch              from "isomorphic-unfetch";
import firebase           from 'firebase/app';

import { parseJwt }       from '../lib/helperMethods';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_PRODUCT_API,
  credentials: "same-origin", 
  fetch
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  let authorization = '';

  if (token) {
    const tokenObj = parseJwt(token);
    // If not expired
    if (!(tokenObj.exp < Date.now() / 1000)) {
      authorization = `Bearer ${token}`;
    } else {
      const user = firebase.auth().currentUser;
      if (user) {
        // Force refresh the token since it has expired
        user.getIdToken(true)
        .then(newToken => {
          localStorage.setItem('token', newToken)
          authorization = `Bearer ${newToken}`;
        });
      }
    }
  };

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization,
    }
  }
});

const createApolloClient = (initialState = {}) => (
  new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          // TODO
        }
        if (networkError) {
          // TODO
        }
      }),
      authLink,
      httpLink
    ]),
    cache: new InMemoryCache({
      addTypename: false
    }).restore(initialState),
  }));

export default createApolloClient;
