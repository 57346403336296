import React from 'react';

const context = React.createContext({
  currentUser: null,
  loginWithFirebase: () => {},
  logoutWithFirebase: () => {},
  firebaseLoading: false,
  setToken: () => {},
  setJWT: () => {},
  setLifecycle: () => {},
  setUser: () => {}
});

export default context;
