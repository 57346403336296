import { useQuery } from '@apollo/client';
import get from 'lodash/get';

import { GET_CURRENT_USER } from './queries';

export const useCurrentUser = () => {
  const query = useQuery(GET_CURRENT_USER, {
    pollInterval: 60000 // poll every minute so that rescheduled/cancelled sessions update quickly
  });

  return {
    ...query,
    data: get(query, 'data.currentUser'),
  };
}