import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/client";
import { useCurrentUser } from "./network/hooks";
import createApolloClient from "./network/apollo";
import context from "./context";
import theme from "./theme";
import "firebase/auth";
import "./index.css";

// Screens
import Home from "./screens/Home.js";
import Auth from "./screens/Auth.js";
import Lifecycle from "./screens/Lifecycle";

// Firebase Config
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const localToken = localStorage.getItem("token");

const client = createApolloClient();

// Root Element
const layout = document.getElementById("layout");

function App() {
  const [JWT, setJWT] = useState(null);
  const [token, setToken] = useState(localToken);
  const [loading, setLoading] = useState(false);
  const [lifecycle, setLifecycle] = useState({});
  const [user, setUser] = useState({});

  useEffect(() => {
    layout.style.height = window.innerHeight + "px";
  }, []);

  useEffect(() => {
    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    }
  }, [token]);

  useEffect(() => {
    if (JWT && !token) {
      _handleLoginWithFirebase(JWT);
      setJWT(null);
    }
  }, [JWT, token]);

  const _handleLoginWithFirebase = async (jwt) => {
    setLoading(true);
    firebase
      .auth()
      .signInWithCustomToken(jwt)
      .then((userCredential) => {
        const {
          user: { refreshToken },
        } = userCredential;
        const user = firebase.auth().currentUser;
        localStorage.setItem("refreshToken", refreshToken);
        if (user) {
          user
            .getIdToken()
            .then((firebaseToken) => {
              localStorage.setItem("token", firebaseToken);
              setToken(firebaseToken);
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.log("getIdToken error", error);
            });
        }
      })
      .catch((e) => console.log("ERROR SIGNING IN WITH CUSTOM TOKEN:", e));
  };

  const _handleLogoutWithFirebase = () => {
    setJWT(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  };

  const appContext = {
    currentUser: useCurrentUser(),
    loginWithFirebase: _handleLoginWithFirebase,
    logoutWithFirebase: _handleLogoutWithFirebase,
    firebaseLoading: loading,
    setToken,
    setJWT,
    setLifecycle,
    setUser
  };

  const _hasLifecycle = Boolean(Object.entries(lifecycle).length);

  //0, 1, or 2
  const _componentIndex = [Number(Boolean(token)), Number(_hasLifecycle)].reduce((total, num) => total + num, 0);

  return (
    <context.Provider value={appContext}>
      <ThemeProvider theme={theme}>
        {[<Auth setJWT={setJWT} loading={loading} />, <Home setToken={setToken} />, <Lifecycle user={user} data={lifecycle} /> ][_componentIndex]}
      </ThemeProvider>
    </context.Provider>
  );
}

const AppHOC = () => {
  // give App access to apollo client
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

ReactDOM.render(<AppHOC />, layout);
