import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import context from "../context";
import Navigation from "../components/Navigation";
import Modal from "../components/Modal";
import { GET_USER_LIFECYCLE } from "../network/queries";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import variables from "../variables";

const Home = () => {
  const { setToken, setUser, setLifecycle } = useContext(context);
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  
  const [getUser, { loading }] = useLazyQuery(GET_USER_LIFECYCLE, {
    variables: {
      [/@/.test(value) ? "email" : "id"]: value
    },
    onCompleted: ({ getUser }) => {
      if (getUser) {
        setUser(getUser);
        setLifecycle(getUser.lifecycle);
      } else {
        setErrorMessage('There was a problem, please try again.');
      }
    },
    onError: e => {
      console.info(("CLIENT QUERY GET_USER_LIFECYCLE ERROR", e));
      setErrorMessage(e.message);
    }
  });

  // On Render Animation
  useEffect(() => {
    setTimeout(() => setIsRendered(true), 200);
  }, []);

  const _handleChange = (e) => {
    // Reset
    if (errorMessage) {
      setErrorMessage("");
    }
    const value = e.target.value;
    setValue(value);
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();
    getUser();
  };

  return (
    <Container isRendered={isRendered}>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <Navigation
        setToken={setToken}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <div
        style={{
          margin: "auto",
          backgroundColor: "transparent",
        }}
      >
        <div style={{ backgroundColor: "transparent" }}>
          <h1 style={{ color: variables.white }}>User Lifecycle</h1>
        </div>
        <Form onSubmit={_handleSubmit}>
          <label
            style={{
              display: "block",
              color: variables.white,
              marginBottom: 10,
            }}
          >
            Enter an email or user id to find their lifecycle below
          </label>
          <TextInput
            onChange={_handleChange}
            value={value}
            attrs={{ name: "value field", placeholder: "User ID or email" }}
          />
          <Button
            disabled={!value.length}
            loading={loading}
            cta={true}
            type="submit"
          >
            Search
          </Button>
        </Form>
        {errorMessage.length && (
          <div style={{ margin: '20px 0' }}>
            <p style={{ color: variables.error }}>{errorMessage}</p>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  opacity: ${({ isRendered }) => (isRendered ? 1 : 0)};
  transition: opacity 2.4s cubic-bezier(0.24, 1, 0.32, 1);
`;

const Form = styled.form`
  width: 300px;
  transition: opacity 0.6s cubic-bezier(0.24, 1, 0.32, 1);
`;
